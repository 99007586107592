//Errors
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//SESSIONS
export const NEW_PROFILE = "NEW_PROFILE";
export const CLEAR_SESSION = "CLEAR_SESSION";


//HOSITAL
export const FETCH_HOSPITAL = "FETCH_HOSPITAL";

//PRODUCT LIST
export const FETCH_PRODUCT_PAC = "FETCH_PRODUCT_PAC";
export const FETCH_PRODUCT_IPC = "FETCH_PRODUCT_IPC";
export const FETCH_PRODUCT_IPC_GARMENT = "FETCH_PRODUCT_IPC_GARMENT";
export const FETCH_PRODUCT_CANCEL= "FETCH_PRODUCT_CANCEL";
export const FETCH_PRODUCT_REQUEST_CANCEL= "FETCH_PRODUCT_REQUEST_CANCEL";

//PATIENT INFO
export const RETRIEVE_PATIENT = "RETRIEVE_PATIENT";
