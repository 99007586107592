import { FETCH_HOSPITAL, RETRIEVE_HOSPITAL } from '../actions/types';

const initialState = {
  hospitals: [],
  hospital: {},
  singleHospital: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_HOSPITAL:
      return {
        ...state,
        hospitals: action.payload
      };
  
   /*   case RETRIEVE_HOSPITAL:
      return {
        ...state,
        roUpdate: action.payload
      };  
   */
    default:
      return state;
  }
}
