import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import sessionReducer from './sessionReducer';
import hospitalReducer from './hospitalReducer';
import productReducer from './productReducer';
import patientReducer from './patientReducer';




export default combineReducers({
  session: sessionReducer,
  errors: errorReducer,
  hospital: hospitalReducer,
  pac_product: productReducer,
  ipc_product: productReducer,
  ipc_garment_product: productReducer,
  cancellationProduct: productReducer,
  requestCancellationProduct: productReducer,
  patient: patientReducer
});
