import {
  // RETRIEVE_USER, 
//  UPDATE_USER, 
  FETCH_PRODUCT_PAC, 
  FETCH_PRODUCT_IPC,
  FETCH_PRODUCT_CANCEL,
  FETCH_PRODUCT_REQUEST_CANCEL,
  FETCH_PRODUCT_IPC_GARMENT
 // DELETE_USER
 } from '../actions/types';

const initialState = {
  pac_products: [],
  pac_product: {},

  ipc_products: [],
  ipc_product: {},

  ipc_garment_products: [],
  ipc_garment_product: {},

  cancellationProducts: [],
  cancellationProduct: {},

  requestCancellationProducts: [],
  requestCancellationProduct: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
   /* case RETRIEVE_USER:
      return {
        ...state,
        user: action.payload
      }; */
    case FETCH_PRODUCT_PAC:
      return {
        ...state,
        pac_products: action.payload
      };
    case FETCH_PRODUCT_IPC:
        return {
          ...state,
          ipc_products: action.payload
        };
        case FETCH_PRODUCT_IPC_GARMENT:
        return {
          ...state,
          ipc_garment_products: action.payload
        };
        case FETCH_PRODUCT_CANCEL:
          return {
            ...state,
            cancellationProducts: action.payload
          };
          case FETCH_PRODUCT_REQUEST_CANCEL:
            return {
              ...state,
              requestCancellationProducts: action.payload
            };
  /*  case DELETE_USER: {

      const newState = JSON.parse(JSON.stringify(state));
      var index = "";

      for(var i=0; i<state.users.length; i++){
        if(action.payload.ID === state.users[i].ID){
          index = i;
          break;
        }
      }

      if (index !== "") {
        newState.users.splice(index, 1);
        return newState;      
      }

    };*/
    default:
      return state;
  }
}
