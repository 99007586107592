import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import { Provider } from 'react-redux';
import store from './store';

const loading = () => (
  <div className='animated fadeIn pt-3 text-center'>
    <i className='fas fa-spinner fa-pulse ' style={{color: '#015174'}} />
    
    </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./screens/LoginScreen/LoginScreen'),
  loading
});
const AreaPin = Loadable({
  loader: () => import('./screens/LoginScreen/areaPinVerification'),
  loading
});
const Hospital = Loadable({
  loader: () => import('./screens/LoginScreen/hospitalVerification'),
  loading
});



class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route exact path='/login' name='Login Page' component={Login} />
            <Route exact path='/areaPinVerification' name='Login Page1' component={AreaPin} />
            <Route exact path='/hospitalVerification' name='Login Page' component={Hospital} />
            <Route path='/' name='Home' component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
