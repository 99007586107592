import { NEW_PROFILE, CLEAR_SESSION } from '../actions/types';

const initialState = {
  profile: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NEW_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    case CLEAR_SESSION:
      return {
        ...state,
        profile: null
      };
    default:
      return state;
  }
}