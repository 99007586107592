import {
   RETRIEVE_PATIENT
 } from '../actions/types';

const initialState = {
  patients: [],
  patient: {},

 
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RETRIEVE_PATIENT:
      return {
        ...state,
        patient: action.payload
      }; 
 /*   case FETCH_PRODUCT_PAC:
      return {
        ...state,
        pac_products: action.payload
      };
      case FETCH_PRODUCT_IPC:
        return {
          ...state,
          ipc_products: action.payload
        };
    case DELETE_USER: {

      const newState = JSON.parse(JSON.stringify(state));
      var index = "";

      for(var i=0; i<state.users.length; i++){
        if(action.payload.ID === state.users[i].ID){
          index = i;
          break;
        }
      }

      if (index !== "") {
        newState.users.splice(index, 1);
        return newState;      
      }

    };*/
    default:
      return state;
  }
}
